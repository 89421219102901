import { render, staticRenderFns } from "./packageOrServiceDetails.vue?vue&type=template&id=07316824&scoped=true"
import script from "./packageOrServiceDetails.vue?vue&type=script&lang=js"
export * from "./packageOrServiceDetails.vue?vue&type=script&lang=js"
import style0 from "./packageOrServiceDetails.vue?vue&type=style&index=0&id=07316824&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "07316824",
  null
  
)

export default component.exports