<template>
  <section>
    <b-modal centered id="refundDetails" title="Details" hide-footer no-close-on-backdrop>
      <b-list-group-item class="border-active border-1  my-1 d-flex d-flex w-100 justify-content-between" v-for="(i, key) in refundDetails" :key="key">
        <span> {{ i.value }}</span>
        <span> {{ i.status }}</span>
        <b-button v-if="i.status == 'approved' && !i.done_at" id="refundDone" variant=" iq-bg-success mr-1 mb-0" size="sm" @click="refundDone(i.id)"><i class="las la-check"></i></b-button>
        <b-button v-if="i.status == 'approved' && !i.done_at" id="refundReject"  variant=" iq-bg-danger mr-1 mb-0" size="sm"
                  @click="refundReject(i.id)"><i class="las la-times"></i></b-button>
        <span v-if="i.done_at">{{i.done_at.substring(0,10)}}</span>
      </b-list-group-item>
    </b-modal>
    <b-modal centered id="packageDetails" title="Details" hide-footer no-close-on-backdrop>
      <package-info :packageInfo="popupPackageInfo"></package-info>
    </b-modal>
    <b-modal id="packageService" size="md" :title="packageName" hide-footer no-close-on-backdrop>
      <servicesPackage :services="servicesPackge"/>
    </b-modal>
    <b-modal id="pay" size="md" :title="packageName" hide-footer no-close-on-backdrop>
      <pay :remainingAmount="remainingAmount" @pay="pay"/>
    </b-modal>
    <b-modal id="addNote" size="md" :title="'Add Note To ' + packageName" hide-footer no-close-on-backdrop>
      <ValidationObserver v-slot="{ handleSubmit }">
        <form @submit.prevent="handleSubmit(sendNote)">
          <b-row>
            <b-col lg="12">
              <div class="new-user-info">
                <b-row>
                  <b-form-group class="col-md-12" label="Note:" label-for="note">
                    <ValidationProvider name="note" ref="note" rules="required" v-slot="{ errors }">
                      <b-form-textarea name="note" v-model="packageNote" id="note" placeholder="" :class="(errors.length > 0 ? ' is-invalid' : '')"
                      ></b-form-textarea>
                      <div class="invalid-feedback">
                        <span>{{ errors[0] }}</span>
                      </div>
                    </ValidationProvider>
                  </b-form-group>
                </b-row>
                <div class="d-flex  mt-4 align-items-center justify-content-end">
                  <b-button v-if='loadingAddNote' class="btn btn-primary float-right" variant="primary" disabled>
                    <b-spinner small type="grow"></b-spinner>
                    loading...
                  </b-button>
                  <b-button v-else variant="primary" type="submit" >Add Note</b-button>
                </div>
              </div>
            </b-col>
          </b-row>
        </form>
      </ValidationObserver>
    </b-modal>
    <b-modal id="showNotes" size="md" :title="packageName" hide-footer no-close-on-backdrop>
      <b-table :items="allNotePackage" :fields="columsNote">
        <template v-slot:cell(created_at)="data1">
          <p>{{timeConvert(data1.item.created_at)}}</p>
        </template>
      </b-table>
      <b-pagination v-model="paginationCurrentPage"
                    class="mt-3"
                    :total-rows="paginationTotal"
                    align="right"
                    :per-page="pagination_per_page"
                    aria-controls="my-table"
                    @input="changeContentPage"
      ></b-pagination>
    </b-modal>
    <b-modal id="freeze" size="md" :title="packageName" hide-footer no-close-on-backdrop>
      <freeze :freezeNumber="frezzeNumber" @freeze="freeze"/>
    </b-modal>
    <b-modal id="invitation" size="md" :title="packageName" hide-footer no-close-on-backdrop>
      <invitation @invite="inviteFriend"/>
    </b-modal>
    <b-modal id="transfer" size="lg" :title="packageName" hide-footer no-close-on-backdrop>
      <transfer @transfer="trnasfer"/>
    </b-modal>
    <b-modal id="breackFrezze" size="md" :title="packageName" hide-footer no-close-on-backdrop>
      <b-container fluid>
        <h5>Are You sure breack frezze in {{packageName}} ?</h5>
        <b-button variant="primary" class="float-right w-25 mt-4" @click="breackFrezze">Yes</b-button>
      </b-container>
    </b-modal>
    <b-modal id="upgrade" size="xl" :title="packageName" hide-footer no-close-on-backdrop>
      <b-container fluid>
        <upgrade :availablePackages="availablePackages" :packageInfoToUpgrade = "packageInfoToUpgrade"
                 @upgrade="upgrade" :loadingUpgradeButton="loadingUpgradeButton"/>
      </b-container>
    </b-modal>
    <b-modal id="wrongEntry" size="md" :title="packageName" hide-footer no-close-on-backdrop>
      <b-container fluid>
        <ValidationObserver v-slot="{ handleSubmit }">
          <form @submit.prevent="handleSubmit(sendWrongEntry)">
            <b-row>
              <b-col lg="12">
                <div class="new-user-info">
                  <b-row>
                    <b-form-group class="col-md-12" label="Reason:" label-for="note">
                      <ValidationProvider name="Reason" ref="Reason" rules="required" v-slot="{ errors }">
                        <b-form-textarea name="Reason" v-model="wrongEntry.reason" id="note" placeholder="Reason..."
                                         :class="(errors.length > 0 ? ' is-invalid' : '')"
                        ></b-form-textarea>
                        <div class="invalid-feedback">
                          <span>{{ errors[0] }}</span>
                        </div>
                      </ValidationProvider>
                    </b-form-group>
                    <section class="position-relative cursor-pointer m-auto">
                      <input type="file" id="attachments" @change="previewAttachemnt" class="position-absolute capture_image" style="opacity: 0;"/>
                      <p ref="attachments" class="m-0 text-primary cursor-pointer text-center w-100"><i
                          class="las la-cloud-upload-alt"></i>{{ wrongEntry.media_id ?'Edit': 'Add' }} Attachemnt</p>
                      <p class="d-block text-success mt-2 w-100 text-center" v-if="wrongEntry.media_id"><i
                          class="las la-cloud-upload-alt"></i> File Uploaded Successfuly</p>
                    </section>
                  </b-row>
                  <div class="d-flex  mt-4 align-items-center justify-content-end">
                    <b-button v-if='loadingAddNote' class="btn btn-primary float-right" variant="primary" disabled>
                      <b-spinner small type="grow"></b-spinner>
                      loading...
                    </b-button>
                    <b-button v-else variant="primary" type="submit" >Add Note</b-button>
                  </div>
                </div>
              </b-col>
            </b-row>
          </form>
        </ValidationObserver>
      </b-container>
    </b-modal>
    <b-modal id="refundRequest" size="md" :title="packageName" hide-footer no-close-on-backdrop>
      <b-container fluid>
        <ValidationObserver v-slot="{ handleSubmit }">
          <form @submit.prevent="handleSubmit(makeRefund)">
            <b-row>
              <b-col lg="12">
                <div class="new-user-info">
                  <b-row>
                    <b-form-group class="col-md-12" label="Comment:" label-for="Comment">
                      <ValidationProvider name="Comment" ref="Comment" rules="required" v-slot="{ errors }">
                        <b-form-textarea name="Comment" v-model="refundComment" id="Comment" placeholder=""
                                         :class="(errors.length > 0 ? ' is-invalid' : '')"
                        ></b-form-textarea>
                        <div class="invalid-feedback">
                          <span>{{ errors[0] }}</span>
                        </div>
                      </ValidationProvider>
                    </b-form-group>
                  </b-row>
                  <div class="d-flex  mt-4 align-items-center justify-content-end">
                    <b-button v-if='loadingMakeRefund' class="btn btn-primary float-right" variant="primary" disabled>
                      <b-spinner small type="grow"></b-spinner>
                      loading...
                    </b-button>
                    <b-button v-else variant="primary" type="submit" >Refund</b-button>
                  </div>
                </div>
              </b-col>
            </b-row>
          </form>
        </ValidationObserver>
      </b-container>
    </b-modal>
    <!-- Roc -->
    <b-modal id="createdAt" size="md" :title="'Created At  ' + packageName" hide-footer no-close-on-backdrop>
      <ValidationObserver v-slot="{ handleSubmit }">
        <form @submit.prevent="handleSubmit(rocCreatedAt)">
          <b-row>
            <b-col lg="12">
              <div class="new-user-info">
                <b-row>
                  <b-form-group class="col-md-12" label="Start Time" label-for="exampleInputtime">
                    <flat-pickr class="form-control" v-model="rocCreatedTime.time"
                                :config="{ maxDate : minStart,
                                           enableTime: true,
                                           dateFormat: 'Y-m-d H:i:ss'
                    }"
                                placeholder="Select Date"></flat-pickr>
                  </b-form-group>
                  <b-form-group class="col-md-12" label="Reason:" label-for="reason">
                    <ValidationProvider name="reason" ref="reason" rules="required" v-slot="{ errors }">
                      <select  class="form-control" id="reason" v-model="rocReasonId">
                        <option selected disabled value="">Choose Reason...</option>
                        <option v-for="(i, key) in allReason" :key="key" :value="i.id">
                          <span>{{i.reason}}</span>
                        </option>
                      </select>
                      <div class="invalid-feedback">
                        <span>{{ errors[0] }}</span>
                      </div>
                    </ValidationProvider>
                  </b-form-group>
                </b-row>
                <div class="d-flex  mt-4 align-items-center justify-content-end">
                  <b-button v-if='loadingDone' class="btn btn-primary float-right" variant="primary" disabled>
                    <b-spinner small type="grow"></b-spinner>
                    loading...
                  </b-button>
                  <b-button v-else variant="primary" type="submit" >Done</b-button>
                </div>
              </div>
            </b-col>
          </b-row>
        </form>
      </ValidationObserver>
    </b-modal>
    <b-modal id="startAt" size="md" :title="'Start At  ' + packageName" hide-footer no-close-on-backdrop>
      <ValidationObserver v-slot="{ handleSubmit }">
        <form @submit.prevent="handleSubmit(rocStartTimeStore)">
          <b-row>
            <b-col lg="12">
              <div class="new-user-info">
                <b-row>
                  <b-form-group class="col-md-12" label="Start Time" label-for="exampleInputtime">
                    <flat-pickr class="form-control" v-model="rocStartTime.startTime"
                                :config="{ minDate : minStart }"
                                placeholder="Select Date">
                    </flat-pickr>
                  </b-form-group>
                  <b-form-group class="col-md-12" label="Reason:" label-for="reason">
                    <ValidationProvider name="reason" ref="reason" rules="required" v-slot="{ errors }">
                      <select  class="form-control" id="reason" v-model="rocReasonId">
                        <option selected disabled value="">Choose Reason...</option>
                        <option v-for="(i, key) in allReason" :key="key" :value="i.id">
                          <span>{{i.reason}}</span>
                        </option>
                      </select>
                      <div class="invalid-feedback">
                        <span>{{ errors[0] }}</span>
                      </div>
                    </ValidationProvider>
                  </b-form-group>
                </b-row>
                <div class="d-flex  mt-4 align-items-center justify-content-end">
                  <b-button v-if='loadingDone' class="btn btn-primary float-right" variant="primary" disabled>
                    <b-spinner small type="grow"></b-spinner>
                    loading...
                  </b-button>
                  <b-button v-else variant="primary" type="submit" >Done</b-button>
                </div>
              </div>
            </b-col>
          </b-row>
        </form>
      </ValidationObserver>
    </b-modal>
    <b-modal id="endAt" size="md" :title="'End At  ' + packageName" hide-footer no-close-on-backdrop>
      <ValidationObserver v-slot="{ handleSubmit }">
        <form @submit.prevent="handleSubmit(rocEndTimeStore)">
          <b-row>
            <b-col lg="12">
              <div class="new-user-info">
                <b-row>
                  <b-form-group class="col-md-12" label="End Time" label-for="exampleInputtime">
                    <flat-pickr class="form-control" v-model="rocEndTime"
                                placeholder="Select Date"></flat-pickr>
                  </b-form-group>
                  <b-form-group class="col-md-12" label="Reason:" label-for="reason">
                    <ValidationProvider name="reason" ref="reason" rules="required" v-slot="{ errors }">
                      <select  class="form-control" id="reason" v-model="rocReasonId">
                        <option selected disabled value="">Choose Reason...</option>
                        <option v-for="(i, key) in allReason" :key="key" :value="i.id">
                          <span>{{i.reason}}</span>
                        </option>
                      </select>
                      <div class="invalid-feedback">
                        <span>{{ errors[0] }}</span>
                      </div>
                    </ValidationProvider>
                  </b-form-group>
                </b-row>
                <div class="d-flex  mt-4 align-items-center justify-content-end">
                  <b-button v-if='loadingDone' class="btn btn-primary float-right" variant="primary" disabled>
                    <b-spinner small type="grow"></b-spinner>
                    loading...
                  </b-button>
                  <b-button v-else variant="primary" type="submit" >Done</b-button>
                </div>
              </div>
            </b-col>
          </b-row>
        </form>
      </ValidationObserver>
    </b-modal>
    <b-modal id="rocCount" size="md" :title="'Count  ' + packageName" hide-footer no-close-on-backdrop>
      <ValidationObserver v-slot="{ handleSubmit }">
        <form @submit.prevent="handleSubmit(rocCountStore)">
          <b-row>
            <b-col lg="12">
              <div class="new-user-info">
                <b-row>
                  <b-form-group class="col-md-12" label="Count" label-for="exampleInputtime">
                    <ValidationProvider name="value" ref="value" :rules="'required|numeric'" v-slot="{ errors }">
                      <b-form-input v-model="rocCount" type="text" :class="(errors.length > 0 ? ' is-invalid' : '')"></b-form-input>
                      <div class="invalid-feedback">
                        <span>{{ errors[0] }}</span>
                      </div>
                    </ValidationProvider>
                  </b-form-group>
                  <b-form-group class="col-md-12" label="Reason:" label-for="reason">
                    <ValidationProvider name="reason" ref="reason" rules="required" v-slot="{ errors }">
                      <select  class="form-control" id="reason" v-model="rocReasonId">
                        <option selected disabled value="">Choose Reason...</option>
                        <option v-for="(i, key) in allReason" :key="key" :value="i.id">
                          <span>{{i.reason}}</span>
                        </option>
                      </select>
                      <div class="invalid-feedback">
                        <span>{{ errors[0] }}</span>
                      </div>
                    </ValidationProvider>
                  </b-form-group>
                </b-row>
                <div class="d-flex  mt-4 align-items-center justify-content-end">
                  <b-button v-if='loadingDone' class="btn btn-primary float-right" variant="primary" disabled>
                    <b-spinner small type="grow"></b-spinner>
                    loading...
                  </b-button>
                  <b-button v-else variant="primary" type="submit" >Done</b-button>
                </div>
              </div>
            </b-col>
          </b-row>
        </form>
      </ValidationObserver>
    </b-modal>
    <b-modal id="rocFreez" size="md" :title="'Freez  ' + packageName" hide-footer no-close-on-backdrop>
      <ValidationObserver v-slot="{ handleSubmit }">
        <form @submit.prevent="handleSubmit(rocFreezStore)">
          <b-row>
            <b-col lg="12">
              <div class="new-user-info">
                <b-row>
                  <b-form-group class="col-md-12" label="Freez" label-for="exampleInputtime">
                    <ValidationProvider name="value" ref="value" :rules="'required|numeric'" v-slot="{ errors }">
                      <b-form-input v-model="rocFreez" type="text" :class="(errors.length > 0 ? ' is-invalid' : '')"></b-form-input>
                      <div class="invalid-feedback">
                        <span>{{ errors[0] }}</span>
                      </div>
                    </ValidationProvider>
                  </b-form-group>
                  <b-form-group class="col-md-12" label="Reason:" label-for="reason">
                    <ValidationProvider name="reason" ref="reason" rules="required" v-slot="{ errors }">
                      <select  class="form-control" id="reason" v-model="rocReasonId">
                        <option selected disabled value="">Choose Reason...</option>
                        <option v-for="(i, key) in allReason" :key="key" :value="i.id">
                          <span>{{i.reason}}</span>
                        </option>
                      </select>
                      <div class="invalid-feedback">
                        <span>{{ errors[0] }}</span>
                      </div>
                    </ValidationProvider>
                  </b-form-group>
                </b-row>
                <div class="d-flex  mt-4 align-items-center justify-content-end">
                  <b-button v-if='loadingDone' class="btn btn-primary float-right" variant="primary" disabled>
                    <b-spinner small type="grow"></b-spinner>
                    loading...
                  </b-button>
                  <b-button v-else variant="primary" type="submit" >Done</b-button>
                </div>
              </div>
            </b-col>
          </b-row>
        </form>
      </ValidationObserver>
    </b-modal>
    <b-modal id="rocInvitation" size="md" :title="'Invitation  ' + packageName" hide-footer no-close-on-backdrop>
      <ValidationObserver v-slot="{ handleSubmit }">
        <form @submit.prevent="handleSubmit(rocInvitationStore)">
          <b-row>
            <b-col lg="12">
              <div class="new-user-info">
                <b-row>
                  <b-form-group class="col-md-12" label="Invitation" label-for="exampleInputtime">
                    <ValidationProvider name="value" ref="value" :rules="'required|numeric'" v-slot="{ errors }">
                      <b-form-input v-model="rocInvitation" type="text"
                                    :class="(errors.length > 0 ? ' is-invalid' : '')"></b-form-input>
                      <div class="invalid-feedback">
                        <span>{{ errors[0] }}</span>
                      </div>
                    </ValidationProvider>
                  </b-form-group>
                  <b-form-group class="col-md-12" label="Reason:" label-for="reason">
                    <ValidationProvider name="reason" ref="reason" rules="required" v-slot="{ errors }">
                      <select  class="form-control" id="reason" v-model="rocReasonId">
                        <option selected disabled value="">Choose Reason...</option>
                        <option v-for="(i, key) in allReason" :key="key" :value="i.id">
                          <span>{{i.reason}}</span>
                        </option>
                      </select>
                      <div class="invalid-feedback">
                        <span>{{ errors[0] }}</span>
                      </div>
                    </ValidationProvider>
                  </b-form-group>
                </b-row>
                <div class="d-flex  mt-4 align-items-center justify-content-end">
                  <b-button v-if='loadingDone' class="btn btn-primary float-right" variant="primary" disabled>
                    <b-spinner small type="grow"></b-spinner>
                    loading...
                  </b-button>
                  <b-button v-else variant="primary" type="submit" >Done</b-button>
                </div>
              </div>
            </b-col>
          </b-row>
        </form>
      </ValidationObserver>
    </b-modal>
    <b-modal id="rocDiscount" size="md" :title="'Discount  ' + packageName" hide-footer no-close-on-backdrop>
      <ValidationObserver v-slot="{ handleSubmit }">
        <form @submit.prevent="handleSubmit(rocDiscountStore)">
          <b-row>
            <b-col lg="12">
              <div class="new-user-info">
                <b-row>
                  <b-form-group class="col-md-12" label="Discount" label-for="exampleInputtime">
                    <ValidationProvider name="value" ref="value" :rules="'required|numeric'" v-slot="{ errors }">
                      <b-form-input v-model="rocDiscount" type="text"
                                    :class="(errors.length > 0 ? ' is-invalid' : '')"></b-form-input>
                      <div class="invalid-feedback">
                        <span>{{ errors[0] }}</span>
                      </div>
                    </ValidationProvider>
                  </b-form-group>
                  <b-form-group class="col-md-12" label="Reason:" label-for="reason">
                    <ValidationProvider name="reason" ref="reason" rules="required" v-slot="{ errors }">
                      <select  class="form-control" id="reason" v-model="rocReasonId">
                        <option selected disabled value="">Choose Reason...</option>
                        <option v-for="(i, key) in allReason" :key="key" :value="i.id">
                          <span>{{i.reason}}</span>
                        </option>
                      </select>
                      <div class="invalid-feedback">
                        <span>{{ errors[0] }}</span>
                      </div>
                    </ValidationProvider>
                  </b-form-group>
                </b-row>
                <div class="d-flex  mt-4 align-items-center justify-content-end">
                  <b-button v-if='loadingDone' class="btn btn-primary float-right" variant="primary" disabled>
                    <b-spinner small type="grow"></b-spinner>
                    loading...
                  </b-button>
                  <b-button v-else variant="primary" type="submit" >Done</b-button>
                </div>
              </div>
            </b-col>
          </b-row>
        </form>
      </ValidationObserver>
    </b-modal>
    <b-modal id="rocSalesPerson" size="md" :title="'Sales Person  ' + packageName" hide-footer no-close-on-backdrop>
      <ValidationObserver v-slot="{ handleSubmit }">
        <form @submit.prevent="handleSubmit(rocSalesPersonStore)">
          <b-row>
            <b-col lg="12">
              <div class="new-user-info">
                <b-row>
                  <b-form-group class="col-md-12" label="User:" label-for="user">
                    <v-select label="users" :options="allUsers" v-model="rocSalesPerson" :filterable="false" :reduce="option =>
                    option.id"
                              @search="onSearch">
                      <template slot="no-options">
                        type to search about users...
                      </template>
                      <template slot="option" slot-scope="option">
                        <div class="d-center">
                          {{ option.name }}
                        </div>
                      </template>
                      <template slot="selected-option" slot-scope="option">
                        <div class="selected d-center">
                          {{ option.name }}
                        </div>
                      </template>
                    </v-select>
                  </b-form-group>
                  <!--                  <b-form-group class="col-md-12" label="Person" label-for="exampleInputtime">
                                      <ValidationProvider name="value" ref="value" :rules="'required'" v-slot="{ errors }">
                                        <b-form-input v-model="rocDiscount" type="text"
                                                      :class="(errors.length > 0 ? ' is-invalid' : '')"></b-form-input>
                                        <div class="invalid-feedback">
                                          <span>{{ errors[0] }}</span>
                                        </div>
                                      </ValidationProvider>
                                    </b-form-group>-->
                  <b-form-group class="col-md-12" label="Reason:" label-for="reason">
                    <ValidationProvider name="reason" ref="reason" rules="required" v-slot="{ errors }">
                      <select  class="form-control" id="reason" v-model="rocReasonId">
                        <option selected disabled value="">Choose Reason...</option>
                        <option v-for="(i, key) in allReason" :key="key" :value="i.id">
                          <span>{{i.reason}}</span>
                        </option>
                      </select>
                      <div class="invalid-feedback">
                        <span>{{ errors[0] }}</span>
                      </div>
                    </ValidationProvider>
                  </b-form-group>
                </b-row>
                <div class="d-flex  mt-4 align-items-center justify-content-end">
                  <b-button v-if='loadingDone' class="btn btn-primary float-right" variant="primary" disabled>
                    <b-spinner small type="grow"></b-spinner>
                    loading...
                  </b-button>
                  <b-button v-else variant="primary" type="submit" >Done</b-button>
                </div>
              </div>
            </b-col>
          </b-row>
        </form>
      </ValidationObserver>
    </b-modal>
    <b-modal id="rocPhone" size="md" :title="'Phone Person  ' + packageName" hide-footer no-close-on-backdrop>
      <ValidationObserver v-slot="{ handleSubmit }">
        <form @submit.prevent="handleSubmit(rocPhone)">
          <b-row>
            <b-col lg="12">
              <div class="new-user-info">
               <b-row>
                 <b-col md="12">
                   <b-form-group label="Reason:" label-for="reason">
                     <ValidationProvider name="reason" ref="reason" rules="required" v-slot="{ errors }">
                       <select  class="form-control" id="reason" v-model="rocReasonId">
                         <option selected disabled value="">Choose Reason...</option>
                         <option v-for="(i, key) in allReason" :key="key" :value="i.id">
                           <span>{{i.reason}}</span>
                         </option>
                       </select>
                       <div class="invalid-feedback">
                         <span>{{ errors[0] }}</span>
                       </div>
                     </ValidationProvider>
                   </b-form-group>
                 </b-col>
                 <b-col md="12">
                   <b-form-group label="phone" label-for="phone">
                     <ValidationProvider name="phone" ref="phone" :rules="'required|numeric'" v-slot="{ errors }">
                       <b-form-input v-model="newPhone" type="text" :class="(errors.length > 0 ? ' is-invalid' : '')"></b-form-input>
                       <div class="invalid-feedback">
                         <span>{{ errors[0] }}</span>
                       </div>
                     </ValidationProvider>
                   </b-form-group>
                 </b-col>
               </b-row>
                <div class="d-flex  mt-4 align-items-center justify-content-end">
                  <b-button v-if='loadingDone' class="btn btn-primary float-right" variant="primary" disabled>
                    <b-spinner small type="grow"></b-spinner>
                    loading...
                  </b-button>
                  <b-button v-else variant="primary" type="submit" >Done</b-button>
                </div>
              </div>
            </b-col>
          </b-row>
        </form>
      </ValidationObserver>
    </b-modal>
    <div v-if="loading" class="text-center">
      <div class="text-cesnter">
        <b-spinner small type="grow"></b-spinner>
        Loading...
      </div>
    </div>
    <div v-else>
      <span v-if="details.length > 0">
        <iq-card body-class="p-0" class="rounded position-relative" v-for="(i, key) in details" :key="key">
        <template v-slot:body>
          <div class="typeOfTag m-0">
             <p v-if="i.type == 'package'" class="m-0">
            <b-badge pill :variant="'success'">
              {{i.type}}
            </b-badge>
          </p>
          <p v-else class="m-0">
            <b-badge pill :variant="'warning'">
              {{i.type}}
            </b-badge>
          </p>
          </div>
          <div class="upgradeOfTag m-0">
             <p v-if="i.upgraded" class="m-0">
            <b-badge pill :variant="'info'">
              Upgraded
            </b-badge>
          </p>
          </div>
          <div class="user-post-data px-3 py-2">
            <div class="d-flex flex-wrap">
              <div class="media-support-info mt-2">
                <h5 class="mb-0" v-if="i.type =='package'" @click="showPackageInfo(i)">
                  <b-link href="#" class="font-size-22 font-weight-bold">{{i.title }}
                  </b-link>
                </h5>
                <h5 class="mb-0" v-else>
                  <b-link href="#" class="font-size-22 font-weight-bold">{{ i.title }}</b-link>
                </h5>
                <p v-if="i.available" class="mb-0 text-primary">Active</p>
                <p v-else class="mb-0 text-danger">Expired</p>
              </div>
              <span class="d-flex align-items-center">
                <b-button variant="secondary" type="submit" class="mr-4" v-if="i.member_refund.length>0 && i.member_refund[0].status == 'pending'">Refund</b-button>
                <b-button variant="success" type="submit" class="mr-4" @click="show_details_refund(i.member_refund)" v-else-if="i.member_refund.length>0 && i.member_refund[0].status == 'approved'">Refund</b-button>
                <b-button variant="danger" type="submit" class="mr-4" v-else-if="i.member_refund.length>0 && i.member_refund[0].status == 'rejected'">Refund</b-button>
                <div class="iq-card-header-toolbar" v-if="i.available && hasPer('membership.roc.create')">
                  <b-dropdown id="dropdownMenuButton40" right variant="none" menu-class="p-0">
                    <template v-slot:button-content>
                         <b-button variant=" iq-bg-danger" size="sm">ROC</b-button>
                    </template>
                    <b-dropdown-item href="#" @click="getAllReasonDependOnType('created_at');showPopupRocCreatedAt(i)">
                      <i class="las la-clock mr-2"></i>Created At
                    </b-dropdown-item>
                    <b-dropdown-item href="#" @click="getAllReasonDependOnType('start_at');showPopupRocStartTime(i)">
                      <i class="las la-clock mr-2"></i>Start At
                    </b-dropdown-item>
                    <b-dropdown-item href="#" @click="getAllReasonDependOnType('expire_at');showPopupRocEndTime(i.id,
                    i.title)">
                      <i class="las la-clock mr-2"></i>Expire At
                    </b-dropdown-item>
                     <b-dropdown-item href="#" @click="getAllReasonDependOnType('count');showPopupRocCount(i.id,
                    i.title)">
                      <i class="las la-calculator mr-2"></i>Count
                    </b-dropdown-item>
                     <b-dropdown-item href="#" @click="getAllReasonDependOnType('freezing');showPopupRocFreez(i.id,
                    i.title)">
                      <i class="las la-snowflake mr-2"></i>Freezing
                    </b-dropdown-item>
                     <b-dropdown-item href="#" @click="getAllReasonDependOnType('invitation');showPopupRocInvitation(i.id,
                    i.title)">
                      <i class="las la-users mr-2"></i>Invitation
                    </b-dropdown-item>
                     <b-dropdown-item href="#" @click="getAllReasonDependOnType('discount');showPopupDiscount(i.id,
                    i.title)">
                      <i class="las la-percent mr-2"></i>Discount
                    </b-dropdown-item>
                    <!--                     <b-dropdown-item href="#">
                                          <i class="las la-money-check mr-2"></i>Payment Methos
                                        </b-dropdown-item>-->
                    <b-dropdown-item href="#" @click="getAllReasonDependOnType('sales_person');showPopupSalesPerson(i.id,
                    i.title)">
                      <i class="las la-user-astronaut mr-2"></i>Sales Person
                    </b-dropdown-item>
                    <b-dropdown-item href="#" @click="getAllReasonDependOnType('phone');showPopupPhone(i.id,
                    i.title)">
                      <i class="las la-phone-volume mr-2"></i>user Phone
                    </b-dropdown-item>
<!--                      <b-dropdown-item href="#"
                                       @click="getAllReasonDependOnType('payment_method');showPopupPayment(i.id,i.title)">
                      <i class="las la-money-bill-wave mr-2"></i>Payment Methods
                    </b-dropdown-item>-->
                </b-dropdown>
                </div>
                <div class="iq-card-header-toolbar" v-if="!i.available && hasPer('membership.roc.create')">
                  <b-dropdown id="dropdownMenuButton40" right variant="none" menu-class="p-0">
                    <template v-slot:button-content>
                         <b-button variant=" iq-bg-danger" size="sm">ROC</b-button>
                    </template>
                    <b-dropdown-item href="#" @click="getAllReasonDependOnType('expire_at');showPopupRocEndTime(i.id,i.title)">
                      <i class="las la-clock mr-2"></i>Expire At
                    </b-dropdown-item>
                </b-dropdown>
                </div>
                <span class="text-primary cursor-pointer mx-3"
                      @click="showServices(i.id, i.title)" v-if="i.type === 'package'">Services</span>
                <div class="iq-card-header-toolbar" v-if="i.available">
                  <b-dropdown id="dropdownMenuButton40" right variant="none" menu-class="p-0">
                    <template v-slot:button-content>
                      <img :src="require('@/assets/options-svgrepo-com.svg')" style="width: 22px"/>
                    </template>
                    <b-dropdown-item href="#" v-if="hasPer('memberships.refund.request')" @click="showRefundPopup(i.id,i.title)">
                      <i class="las la-dollar-sign mr-2"></i>Refund
                    </b-dropdown-item>
                    <b-dropdown-item href="#" v-if="!i.freezing || new Date(i.freezing) < new Date()"
                                     @click="showPopupUpgrade(i.package_id,i.id, i.title,i)">
                      <i class="las la-inbox mr-2"></i>Upgrade</b-dropdown-item>
                    <b-dropdown-item v-if="i.available_invitation" @click="showInvitation(i.id, i.title)"><i class="ri-user-add-line mr-2"></i>Invitations</b-dropdown-item>
                    <b-dropdown-item href="#" @click="showTransfer(i.id, i.title)"><i class="las la-exchange-alt mr-2"></i>Transfer</b-dropdown-item>
                    <b-dropdown-item v-if="i.remaining != 0" @click="showPay(i.id, i.title, i.remaining)"><i class="las la-money-bill-wave mr-2"></i>Pay</b-dropdown-item>
                    <b-dropdown-item v-if="i.available_freezing" @click="showFreeze(i.id, i.title, i.available_freezing)"><i class="las la-snowflake mr-2"></i>Freeze</b-dropdown-item>
                    <b-dropdown-item v-if="!i.freezing || new Date(i.freezing) >= new Date()"
                                     @click="showPopupBreackFrezze(i.id, i.title)"><i class="las la-snowflake mr-2"></i>Breack Freeze</b-dropdown-item>
                    <b-dropdown-item @click="showPopupNote(i.id, i.title)"><i class="las la-comment mr-2"></i>Add
                      Note</b-dropdown-item>
                     <b-dropdown-item @click="showPopupNoteData(i.id, i.title)"><i class="las la-comments mr-2"></i>Show
                      Notes</b-dropdown-item>
                     <b-dropdown-item v-if="hasPer('membership.wrong.entry.create')" @click="showPopupWrongEntry(i.id, i.title)"
                                      class="text-danger"><i
                         class="las la-exclamation-triangle mr-2"></i>Wrong Entry
                     </b-dropdown-item>
                </b-dropdown>
                </div>
                   <span v-if="!i.available" @click="showPopupNoteData(i.id, i.title)"><i class="las la-comments mr-2"></i>Show
                      Notes</span>
              </span>
            </div>
          </div>
        </template>
        <hr class="mt-0">
        <div class="user-post">
          <div class="px-3 pt-1 pb-2">
            <div class="about-info m-0 p-0">
              <b-row>
                <div class="col-lg-6 mb-2">
                  <b-row>
                    <b-col cols="5" class="text-success">Start Date: </b-col>
                    <b-col cols="7">{{ i.start_at }}</b-col>
                  </b-row>
                </div>
                <div class="col-lg-6 mb-2">
                  <b-row>
                    <b-col cols="5" class="text-black" >Created By: </b-col>
                    <b-col cols="7">{{ i.user.created_by }}</b-col>
                  </b-row>
                </div>
                <div class="col-lg-6 mb-2">
                  <b-row>
                    <b-col cols="5" class="text-danger">End Date: </b-col>
                    <b-col cols="7">{{ i.expire_at }}</b-col>
                  </b-row>
                </div>
                <div class="col-lg-6 mb-2">
                  <b-row>
                           <b-col cols="5" class="text-black" >Created At: </b-col>
                    <b-col cols="7">  {{timeConvert(i.created_at)}}</b-col>
                  </b-row>
                </div>
                <div class="col-lg-6 mb-2">
                  <b-row>
                    <b-col cols="5" class="text-black">Freeze: </b-col>
                    <b-col cols="7">{{ i.available_freezing }}  <span class="text-warning" v-if="i.freezing">( {{i.freezing}} )</span></b-col>
                  </b-row>
                </div>
                <div class="col-lg-6 mb-2">
                  <b-row>
                    <b-col cols="5" class="text-black">Sales Person: </b-col>
                    <b-col cols="7">{{ i.sales_target ? i.sales_target.sales.name : 'From Branch' }}</b-col>
                  </b-row>
                </div>
                <div class="col-lg-6 mb-2">
                  <b-row>
                    <b-col cols="5" class="text-black">Invitations: </b-col>
                    <b-col cols="7">{{ i.available_invitation }}</b-col>
                  </b-row>
                </div>
                <div class="col-lg-6 mb-2">
                  <b-row>
                    <b-col cols="5" class="text-black">Count: </b-col>
                    <b-col cols="7">{{ i.available_visits }}</b-col>
                  </b-row>
                </div>
                <div class="col-lg-6 mb-2">
                  <b-row>
                    <b-col cols="5" class="text-black">Cost: </b-col>
                    <b-col cols="7">{{ parseFloat(i.cost) }} EGP</b-col>
                  </b-row>
                </div>
                <div class="col-lg-6 mb-2">
                  <b-row>
                    <b-col cols="5" class="text-black">Discount: </b-col>
                    <b-col cols="7">{{ parseFloat(i.discount) }} EGP</b-col>
                  </b-row>
                </div>
                <div class="col-lg-6 mb-2">
                  <b-row>
                    <b-col cols="5" class="text-black">After Discount: </b-col>
                    <b-col cols="7">{{ parseFloat(i.net) }} EGP</b-col>
                  </b-row>
                </div>
                <div class="col-lg-6 mb-2">
                  <b-row>
                    <b-col cols="5" class="text-black">Paid Amount: </b-col>
                    <b-col cols="7">{{ parseFloat(i.paid) }} EGP</b-col>
                  </b-row>
                </div>
                <div class="col-lg-6 mb-2">
                  <b-row>
                    <b-col cols="5" class="text-black">Remaining Amount: </b-col>
                    <b-col cols="7">{{ parseFloat(i.remaining) }} EGP</b-col>
                  </b-row>
                </div>
                <!--                <div class="col-lg-6 mb-2">
                                  <p class="text-primary cursor-pointer font-weight-bold">
                                    <i class="las la-comment mr-2"></i> ShowNotes</p>
                                </div>-->
              </b-row>
            </div>
          </div>
        </div>
      </iq-card>
      </span>
      <iq-card v-else body-class="p-0" class="rounded text-center">
        <img :src="require('../../../assets/images/error/search.png')" class="avatar-200" />
        <p class="font-size-18 pb-2">Oops!... no data found</p>
      </iq-card>
    </div>
  </section>
</template>

<script>
import servicesPackage from './modal/servicesPackage'
import pay from './modal/pay'
import freeze from './modal/freeze'
import invitation from './modal/invitation'
import transfer from './modal/transfer'
import upgrade from './modal/upgrade'
import { core } from '../../../config/pluginInit'
import packageInfo from '../../packageInfo'
import eventBus from '../../../eventBus'
import service from '../service'
import wrongEndtryService from '@/modules/wrongEntry/services/rongEntry'
import { commonDataMixin } from '@/mixins/commonMixin'

export default {
  name: 'packageOrServiceDetails',
  mixins: [commonDataMixin],
  props: {
    details: {
      type: Array
    },
    loading: {
      type: Boolean
    }
  },
  data () {
    return {
      newPhone: '',
      loadingUpgradeButton: false,
      popupPackageInfo: {},
      servicesPackge: [],
      packageId: '',
      packageName: '',
      remainingAmount: '',
      frezzeNumber: '',
      package_main_id: '',
      availablePackages: [],
      refundDetails: '',
      packageInfoToUpgrade: {},
      packageNote: '',
      loadingAddNote: false,
      allNotePackage: [],
      payment: 'cash',
      columsNote: [
        {
          key: 'user.name',
          label: 'User Name'
        },
        'note',
        {
          key: 'created_at',
          label: 'Created At'
        }
      ],
      wrongEntry: {
        reason: '',
        media_id: '',
        member_package: ''
      },
      loadingMakeRefund: false,
      refundComment: '',
      paginationCurrentPage: 1,
      pagination_per_page: 10,
      paginationTotal: 6,
      rocStartTime: {
        startTime: ''
      },
      rocCreatedTime: {
        time: ''
      },
      minStart: '',
      rocEndTime: '',
      rocReasonId: '',
      allReason: [],
      rocCount: '',
      rocFreez: '',
      rocInvitation: '',
      rocDiscount: '',
      loadingDone: false,
      rocSalesPerson: '',
      allUsers: []
    }
  },
  methods: {
    refundReject (id) {
      document.getElementById('refundReject').innerHTML = '<span class="spinner-grow spinner-grow-sm"></span>'
      this.$store.dispatch('submitStatus', { refundId: id, data: { status: 'rejected', value: 0 } }).then(res => {
        core.showSnackbar('success', res.data.message)
        this.$bvModal.hide('refundDetails')
      }).catch(err => {
        if (err.response.data.errors) {
          for (const [key, value] of Object.entries(err.response.data.errors)) {
            this.$refs[key].setErrors(value)
          }
        } else if (err.response.data.error) {
          core.showSnackbar('error', err.response.data.error)
        }
      }).finally(() => {
        document.getElementById('refundReject').innerHTML = '<i class="las la-times"></i>'
      })
    },
    showPackageInfo (info) {
      this.$store.dispatch('getSpacificPackages', info.package_id).then(data => {
        // console.log(data.data.data)
        this.popupPackageInfo = data.data.data
        this.$bvModal.show('packageDetails')
      })
    },
    showServices (packageID, packageName) {
      this.packageName = packageName
      this.servicesPackge = this.$store.getters.getServicesOfPackage(packageID)
      this.$bvModal.show('packageService')
    },
    showPay (packageID, packageName, remaining) {
      this.packageId = packageID
      this.packageName = packageName
      this.remainingAmount = remaining
      this.$bvModal.show('pay')
    },
    showFreeze (packageID, packageName, frezzeNumber) {
      this.packageId = packageID
      this.packageName = packageName
      this.frezzeNumber = frezzeNumber
      this.$bvModal.show('freeze')
    },
    showInvitation (packageID, packageName) {
      this.packageId = packageID
      this.packageName = packageName
      this.$bvModal.show('invitation')
    },
    showTransfer (packageID, packageName) {
      this.packageId = packageID
      this.packageName = packageName
      this.$bvModal.show('transfer')
    },
    showPopupBreackFrezze (packageId, packageName) {
      this.packageId = packageId
      this.packageName = packageName
      this.$bvModal.show('breackFrezze')
    },
    showPopupNote (packageId, packageName) {
      this.packageId = packageId
      this.packageName = packageName
      this.$bvModal.show('addNote')
    },
    showPopupNoteData (packageId, packageName) {
      this.packageName = packageName
      this.packageId = packageId
      service.getAllPackageNote(packageId).then(res => {
        this.allNotePackage = res.data.data
        this.paginationCurrentPage = res.data.meta.current_page
        this.pagination_per_page = res.data.meta.per_page
        this.paginationTotal = res.data.meta.total
        this.$bvModal.show('showNotes')
      })
    },
    showPopupWrongEntry (packageId, packageName) {
      this.packageName = packageName
      this.packageId = packageId
      this.wrongEntry = {
        reason: '',
        media_id: '',
        member_package: packageId
      }
      this.$bvModal.show('wrongEntry')
    },
    showPopupPhone (packageId, packageName) {
      this.packageName = packageName
      this.packageId = packageId
      this.newPhone = ''
      this.$bvModal.show('rocPhone')
    },
    showPopupPayment (packageId, packageName) {
      this.packageName = packageName
      this.packageId = packageId
      this.payment = ''
      this.$bvModal.show('rocPayment')
    },
    // Roc
    getAllReasonDependOnType (type) {
      service.getAllReasonDependOnType(type).then(res => {
        this.allReason = res.data.data
      })
    },
    showPopupRocStartTime (payload) {
      this.packageName = payload.title
      this.packageId = payload.id
      console.log('payload', payload)
      this.minStart = payload.created_at.substring(0, 10)
      this.rocStartTime = {
        startTime: ''
      }
      this.$bvModal.show('startAt')
    },
    showPopupRocCreatedAt (payload) {
      this.packageName = payload.title
      this.packageId = payload.id
      this.minStart = payload.created_at.substring(0, 10)
      this.rocCreatedTime = {
        time: ''
      }
      this.$bvModal.show('createdAt')
    },
    showPopupRocEndTime (packageId, packageName) {
      this.packageName = packageName
      this.packageId = packageId
      this.rocEndTime = ''
      this.$bvModal.show('endAt')
    },
    showPopupRocCount (packageId, packageName) {
      this.packageName = packageName
      this.packageId = packageId
      this.rocCount = ''
      this.$bvModal.show('rocCount')
    },
    showPopupRocFreez (packageId, packageName) {
      this.packageName = packageName
      this.packageId = packageId
      this.rocFreez = ''
      this.$bvModal.show('rocFreez')
    },
    showPopupRocInvitation (packageId, packageName) {
      this.packageName = packageName
      this.packageId = packageId
      this.rocInvitation = ''
      this.$bvModal.show('rocInvitation')
    },
    showPopupDiscount (packageId, packageName) {
      this.packageName = packageName
      this.packageId = packageId
      this.rocDiscount = ''
      this.$bvModal.show('rocDiscount')
    },
    showPopupSalesPerson (packageId, packageName) {
      this.packageName = packageName
      this.packageId = packageId
      this.rocPerson = ''
      this.$bvModal.show('rocSalesPerson')
    },
    rocCreatedAt () {
      this.loadingDone = true
      service.rocRequest({
        package: this.packageId,
        reason: this.rocReasonId,
        value: this.rocCreatedTime.time
      }).then(res => {
        core.showSnackbar('success', res.data.message)
        this.$bvModal.hide('createdAt')
      }).finally(() => {
        this.loadingDone = false
      })
    },
    rocStartTimeStore () {
      this.loadingDone = true
      service.rocRequest({
        package: this.packageId,
        reason: this.rocReasonId,
        value: this.rocStartTime.startTime
      }).then(res => {
        core.showSnackbar('success', res.data.message)
        this.$bvModal.hide('startAt')
      }).finally(() => {
        this.loadingDone = false
      })
    },
    rocEndTimeStore () {
      this.loadingDone = true
      service.rocRequest({
        package: this.packageId,
        reason: this.rocReasonId,
        value: this.rocEndTime
      }).then(res => {
        core.showSnackbar('success', res.data.message)
        this.$bvModal.hide('endAt')
      }).finally(() => {
        this.loadingDone = false
      })
    },
    rocCountStore () {
      this.loadingDone = true
      service.rocRequest({
        package: this.packageId,
        reason: this.rocReasonId,
        value: this.rocCount
      }).then(res => {
        core.showSnackbar('success', res.data.message)
        this.$bvModal.hide('rocCount')
      }).finally(() => {
        this.loadingDone = false
      })
    },
    rocFreezStore () {
      this.loadingDone = true
      service.rocRequest({
        package: this.packageId,
        reason: this.rocReasonId,
        value: this.rocFreez
      }).then(res => {
        core.showSnackbar('success', res.data.message)
        this.$bvModal.hide('rocFreez')
      }).finally(() => {
        this.loadingDone = false
      })
    },
    rocInvitationStore () {
      this.loadingDone = true
      service.rocRequest({
        package: this.packageId,
        reason: this.rocReasonId,
        value: this.rocInvitation
      }).then(res => {
        core.showSnackbar('success', res.data.message)
        this.$bvModal.hide('rocInvitation')
      }).finally(() => {
        this.loadingDone = false
      })
    },
    rocDiscountStore () {
      this.loadingDone = true
      service.rocRequest({
        package: this.packageId,
        reason: this.rocReasonId,
        value: this.rocDiscount
      }).then(res => {
        core.showSnackbar('success', res.data.message)
        this.$bvModal.hide('rocDiscount')
      }).finally(() => {
        this.loadingDone = false
      })
    },
    rocSalesPersonStore () {
      this.loadingDone = true
      service.rocRequest({
        package: this.packageId,
        reason: this.rocReasonId,
        value: this.rocSalesPerson
      }).then(res => {
        core.showSnackbar('success', res.data.message)
        this.$bvModal.hide('rocSalesPerson')
      }).finally(() => {
        this.loadingDone = false
      })
    },
    rocPhone () {
      this.loadingDone = true
      service.rocRequest({
        package: this.packageId,
        reason: this.rocReasonId,
        value: this.newPhone
      }).then(res => {
        core.showSnackbar('success', res.data.message)
        this.$bvModal.hide('rocPhone')
      }).finally(() => {
        this.loadingDone = false
      })
    },
    rocPayment () {
      this.loadingDone = true
      service.rocRequest({
        package: this.packageId,
        reason: this.rocReasonId,
        value: this.payment
      }).then(res => {
        core.showSnackbar('success', res.data.message)
        this.$bvModal.hide('rocPayment')
      }).finally(() => {
        this.loadingDone = false
      })
    },
    isSelected (i) {
      // eslint-disable-next-line eqeqeq
      return i == this.payment
    },
    onSearch (search, loading) {
      if (search.length) {
        loading(true)
        this.$store.dispatch('getAllAgents', search).then(res => {
          this.allUsers = res.data.data
          loading(false)
        }).catch(() => {
          loading(false)
        })
      }
    },
    changeContentPage () {
      service.getAllPackageNote(this.packageId, this.paginationCurrentPage).then(res => {
        this.allNotePackage = res.data.data
        this.paginationCurrentPage = res.data.meta.current_page
        this.pagination_per_page = res.data.meta.per_page
        this.paginationTotal = res.data.meta.total
      })
    },
    showPopupUpgrade (packageId, packageID, packageName, infoPackage) {
      this.packageInfoToUpgrade = infoPackage
      this.package_main_id = packageID
      this.$store.dispatch('getAvailablePackageToUpgrade', packageId).then(res => {
        this.availablePackages = res.data.data
      })
      this.packageId = packageId
      this.packageName = packageName
      this.$bvModal.show('upgrade')
    },
    pay (data) {
      this.$store.dispatch('payRemaining', { memberId: this.$route.params.id, packageId: this.packageId, data: data }).then(res => {
        // eslint-disable-next-line eqeqeq
        var position = this.details.findIndex(p => p.id == this.packageId)
        this.details[position].paid = parseFloat(this.details[position].paid) + parseFloat(data.value)
        this.details[position].remaining = parseFloat(this.details[position].remaining) - parseFloat(data.value)
        core.showSnackbar('success', res.data.message)
        this.$bvModal.hide('pay')
        this.$emit('reload')
      }).catch(err => {
        // eslint-disable-next-line no-unused-vars
        for (const [key, value] of Object.entries(err.response.data.errors)) {
          core.showSnackbar('error', value)
        }
      })
    },
    freeze (data) {
      this.$store.dispatch('storeFrezze', {
        userId: this.$route.params.id,
        data: { member_package: this.packageId, start: data.start, weeks: data.weeks }
      }).then(res => {
        // eslint-disable-next-line eqeqeq
        var position = this.details.findIndex(p => p.id == this.packageId)
        this.details[position].available_freezing = parseFloat(this.details[position].available_freezing) - res.data.data.weeks_count
        this.details[position].freezing = res.data.data.end.date.toString().substring(0, 10)
        core.showSnackbar('success', res.data.message)
        this.$bvModal.hide('freeze')
        this.$emit('reload')
      }).catch(err => {
        // eslint-disable-next-line no-unused-vars
        for (const [key, value] of Object.entries(err.response.data.errors)) {
          core.showSnackbar('error', value)
        }
      })
    },
    inviteFriend (data) {
      this.$store.dispatch('inviteFriend', {
        memberId: this.$route.params.id,
        data: { member_package: this.packageId, phone: data.phone, name: data.name, national: data.national }
      }).then(res => {
        // eslint-disable-next-line eqeqeq
        var position = this.details.findIndex(p => p.id == this.packageId)
        this.details[position].available_invitation = parseFloat(this.details[position].available_invitation) - 1
        core.showSnackbar('success', res.data.message)
        this.$bvModal.hide('invitation')
        eventBus.$emit('invitation_event')
      }).catch(err => {
        // eslint-disable-next-line no-unused-vars
        for (const [key, value] of Object.entries(err.response.data.errors)) {
          core.showSnackbar('error', value)
        }
      })
    },
    trnasfer (data) {
      this.$store.dispatch('transferPackage', {
        memberId: this.$route.params.id,
        data: {
          transfer_to: data.id,
          fees: data.fees,
          payment_method: data.payment_method,
          member_packages: [this.packageId]
        }
      }).then(res => {
        core.showSnackbar('success', res.data.message)
        this.$bvModal.hide('transfer')
        this.$emit('reload')
      }).catch(err => {
        core.showSnackbar('error', err.response.data.error)
      })
    },
    breackFrezze () {
      this.$store.dispatch('breackFrezze', {
        packageId: this.packageId,
        memberId: this.$route.params.id
      }).then(res => {
        core.showSnackbar('success', res.data.message)
        this.$emit('reload')
        this.$bvModal.hide('breackFrezze')
      }).catch(err => {
        core.showSnackbar('error', err.response.data.error)
      })
    },
    upgrade (details) {
      this.loadingUpgradeButton = true
      // console.log(details)
      this.$store.dispatch('upgradePackage', {
        memberId: this.$route.params.id,
        data: {
          package_to: details.details.packageId,
          fees: details.details.fees,
          payment_method: details.details.payment_method,
          member_package: this.package_main_id
        }
      }).then(res => {
        // console.log('res', res)
        core.showSnackbar('success', res.data.message)
        this.$bvModal.hide('upgrade')
        this.$emit('reload')
      }).catch(err => {
        if (err.response.data.errors) {
          for (const [key, value] of Object.entries(err.response.data.errors)) {
            this.$refs[key].setErrors(value)
          }
        } else if (err.response.data.error) {
          core.showSnackbar('error', err.response.data.error)
        }
      }).finally(() => {
        this.loadingUpgradeButton = false
      })
    },
    show_details_refund (details) {
      this.refundDetails = details
      this.$bvModal.show('refundDetails')
    },
    sendNote () {
      this.loadingAddNote = true
      service.addNoteToPackage(this.packageId, { note: this.packageNote }).then(res => {
        core.showSnackbar('success', res.data.message)
        this.$bvModal.hide('addNote')
        this.packageNote = ''
        this.loadingAddNote = false
      })
    },
    refundDone (id) {
      // console.log(id)
      document.getElementById('refundDone').innerHTML = '<span class="spinner-grow spinner-grow-sm"></span>'
      this.$store.dispatch('refundDone', { userId: this.$route.params.id, id: id }).then(res => {
        core.showSnackbar('success', res.data.message)
        this.$bvModal.hide('refundDetails')
        this.$emit('reload')
      }).catch(err => {
        core.showSnackbar('error', err.response.data.error)
      }).finally(() => {
        document.getElementById('refundDone').innerHTML = '<i class="las la-check"></i>'
      })
    },
    previewAttachemnt: function (e) {
      var files = e.target.files || e.dataTransfer.files
      if (!files.length) return
      this.createAttachemnt(files[0])
      const fd = new FormData()
      fd.append('file', files[0], files[0].name)
      fd.append('folder', 'wrong-entry')
      fd.append('access', 'public')
      this.commonUploadFiles(fd).then(res => {
        this.wrongEntry.media_id = res.data.data.media_id
      })
    },
    createAttachemnt (file) {
      var reader = new FileReader()
      reader.readAsDataURL(file)
    },
    sendWrongEntry () {
      wrongEndtryService.requestWrongEntry(this.wrongEntry).then(res => {
        core.showSnackbar('success', res.data.message)
        this.$bvModal.hide('wrongEntry')
        this.wrongEntry = {
          reason: '',
          media_id: '',
          member_package: ''
        }
      })
    },
    showRefundPopup (packageID, packageName) {
      this.packageId = packageID
      this.packageName = packageName
      this.$bvModal.show('refundRequest')
    },
    makeRefund () {
      this.loadingMakeRefund = true
      this.$store.dispatch('refundRequest', {
        userId: this.$route.params.id,
        data: {
          member_package: this.packageId,
          comment: this.refundComment
        }
      }).then(res => {
        core.showSnackbar('success', res.data.message)
        this.loadingMakeRefund = false
        this.$bvModal.hide('refundRequest')
        this.$emit('reload')
      }).catch(err => {
        this.loadingMakeRefund = false
        if (err.response.data.errors) {
          // eslint-disable-next-line no-unused-vars
          for (const [key, value] of Object.entries(err.response.data.errors)) {
            core.showSnackbar('error', value)
          }
        } else if (err.response.data.error) {
          core.showSnackbar('error', err.response.data.error)
        }
      })
    }
  },
  components: {
    servicesPackage,
    pay,
    invitation,
    freeze,
    transfer,
    upgrade,
    packageInfo
  }
}
</script>

<style scoped>
.typeOfTag {
  position: absolute;
  top: -14px;
  font-size: 16px;
  left: 10px;
}
.upgradeOfTag {
  position: absolute;
  top: -14px;
  font-size: 16px;
  left: 85px;
}
</style>
