import Api from '@/axios'

export default {
  getAllPackageNote (packageId, page = 1) {
    return Api().get(`memberships/package/note/${packageId}?page=${page}`)
  },
  addNoteToPackage (packageId, note) {
    return Api().post(`memberships/package/note/${packageId}`, note)
  },
  rocRequest (payload) {
    return Api().post('memberships/rocs/store', payload)
  },
  getAllReasonDependOnType (group) {
    return Api().get(`memberships/settings/roc/reason/${group}`)
  }
}
