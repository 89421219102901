  <template>
      <b-row>
        <div v-if="progressUpload !== 0 &&  progressUpload !==100" class="loading-full-screen left-0 top-0 position-fixed d-flex justify-content-center align-items-center w-100 h-100 text-white">
          Uploading {{ progressUpload }}%
        </div>
        <b-modal id="rocPayment" size="md" :title="'Payment Method  ' + packageNameRoc" hide-footer
                 no-close-on-backdrop>
          <ValidationObserver v-slot="{ handleSubmit }">
            <form @submit.prevent="handleSubmit(rocPayment)">
              <b-row>
                <b-col lg="12">
                  <div class="new-user-info">
                    <b-row>
                      <b-col md="12">
                        <b-form-group label="Reason:" label-for="reason">
                          <ValidationProvider name="reason" ref="reason" rules="required" v-slot="{ errors }">
                            <select  class="form-control" id="reason" v-model="rocReasonId">
                              <option selected disabled value="">Choose Reason...</option>
                              <option v-for="(i, key) in allReason" :key="key" :value="i.id">
                                <span>{{i.reason}}</span>
                              </option>
                            </select>
                            <div class="invalid-feedback">
                              <span>{{ errors[0] }}</span>
                            </div>
                          </ValidationProvider>
                        </b-form-group>
                      </b-col>
                      <b-col md="12">
                        <b-form-group label="Payment Type:" label-for="period">
                          <div class="periodNumbers">
                            <span :class="[{ 'shadow selectedNumber' : isSelected('cash')}]" @click="newPaymentMethod = 'cash'">
                              Cash
                            </span>
                            <span :class="[{ 'shadow selectedNumber' : isSelected('visa')}]" @click="newPaymentMethod = 'visa'">
                              Visa
                            </span>
                            <span :class="[{ 'shadow selectedNumber' : isSelected('vodafone_cash')}]" @click="newPaymentMethod = 'vodafone_cash'">
                              Vodafone Cash
                            </span>
                            <span :class="[{ 'shadow selectedNumber' : isSelected('paymob')}]" @click="newPaymentMethod = 'paymob'">
                              Paymob
                            </span>
                          </div>
                        </b-form-group>
                      </b-col>
                    </b-row>
                    <div class="d-flex  mt-4 align-items-center justify-content-end">
                      <b-button v-if='loadingDone' class="btn btn-primary float-right" variant="primary" disabled>
                        <b-spinner small type="grow"></b-spinner>
                        loading...
                      </b-button>
                      <b-button v-else variant="primary" type="submit" >Done</b-button>
                    </div>
                  </div>
                </b-col>
              </b-row>
            </form>
          </ValidationObserver>
        </b-modal>
        <b-modal centered id="userCard" title="User Card" size="lg" hide-footer no-close-on-backdrop>
          <div class="cardDetails p-4">
            <header class="d-flex justify-content-between align-items-center">
              <img :src="logo" class="w-25 img-fluid"  />
              <h4 class="px-1">Branch : <span class="text-primary">{{branchName}}</span></h4>
            </header>
            <hr/>
            <section class="mt-5">
              <h4 class="mb-4 font-weight-bold"><i class="las la-user"></i> Member Info</h4>
              <b-row class="text-primary mb-2">
                <b-col sm="2" class="text-black">
                  <h5>Name:</h5>
                </b-col>

                <b-col sm="10">
                  <p>{{ user.name }}</p>
                </b-col>
              </b-row>
              <b-row class="text-primary mb-2">
                <b-col sm="2" class="text-black">
                  <h5>Member ID:</h5>
                </b-col>

                <b-col sm="10">
                  <p>{{ user.membership_id }}</p>
                </b-col>
              </b-row>
              <b-row class="text-primary mb-2">
                <b-col sm="2" class="text-black">
                  <h5>Phone:</h5>
                </b-col>

                <b-col sm="10">
                  <p>{{ user.phone }}</p>
                </b-col>
              </b-row>
<!--              <b-row class="text-primary mb-2">
                <b-col sm="2" class="text-black">
                  <h5>QR:</h5>
                </b-col>
                <b-col sm="10">
                  <img alt="search button" :src="'data:image/svg+xml;base64,' + user.QrCode" />
                </b-col>
              </b-row>-->
            </section>
            <hr/>
            <section class="mt-5">
              <h4 class="mb-4 font-weight-bold"><i class="las la-cubes"></i> Package Info</h4>
              <b-row class="text-primary mb-2">
                <b-col sm="12" v-for="(i, key) in this.getMemberPackagesOrMemberServices" :key="key">
                  <p>{{i.title}}</p>
                </b-col>
              </b-row>
            </section>
          </div>
          <div class="d-flex justify-content-center">
          <b-button variant="primary" class="w-25" @click="printInfo">
            <i class="las la-print"></i>Print
          </b-button>
          </div>
        </b-modal>
        <b-modal centered id="popupImage" title="Image" size="md" hide-footer>
          <img :src="popupImageSrc"  class="w-100"/>
        </b-modal>
        <b-modal centered id="imageContract" title="Contract" size="xl" hide-footer>
          <div dir="rtl" v-html="imageContract"></div>
        </b-modal>
        <b-col sm="12">
          <iq-card body-class=" profile-page p-0">
            <template v-slot:body>
              <div class="profile-header">
                <div class="profile-info p-2">
                  <b-row>
                    <b-col md="6" sm="12">
                      <div class="user-detail pl-5">
  <!--                      {{userImage}}-->
                        <div class="d-flex flex-wrap align-items-center">
                          <div class="profile-div text-center m-auto position-relative" :style="{
                            backgroundImage:'url(' + userImage+ ')' }"
                               @click="viewMemberImage">
                            <span
                                ref="file"
                                class="position-absolute d-block capture_image"
                            ><i class="las la-camera"></i></span>
                            <input type="file" id="file" @change="previewImage" class=" position-absolute capture_image"
                                   accept="image/*" style="opacity: 0;"/>
                            <div class="loadingSection" v-if="progressBar !==0 && progressBar !==100">
                              {{progressBar}} %
                            </div>
                          </div>
                          <div class="profile-detail d-flex align-items-center">
                            <h4>{{user.name}}</h4>
                            <h5 class="m-0 pl-3 text-secondary">{{user.phone}}</h5>
                          </div>
                        </div>
                      </div>
                    </b-col>
                    <b-col md="6" sm="12" class="d-flex align-items-center justify-content-center">
                      <tab-nav :pills="true" id="pills-tab" class="profile-feed-items d-flex justify-content-between">
                        <tab-nav-items :active="true" id="pills-feed-tab" href="#profile-package" ariaControls="pills-home" role="tab" :ariaSelected="true" title="Membership" />
                        <tab-nav-items :active="false" id="pills-friend-tab" href="#profile-friends" ariaControls="pills-contact" role="tab" :ariaSelected="false" title="Financial" @click="getAllPayments"/>
                        <tab-nav-items :active="false" id="pills-attachments-tab"  href="#profile-attachments" ariaControls="pills-contact" role="tab" :ariaSelected="false" title="Attachments" />
                        <tab-nav-items :active="false" id="pills-activities-tab" href="#profile-activity" ariaControls="pills-contact" role="tab" :ariaSelected="false" title="activities" @click="getFreezeReport(); getFreezeReport(); getRefundReport()"/>
                      </tab-nav>
                    </b-col>
                  </b-row>
                </div>
              </div>
            </template>
          </iq-card>
        </b-col>
        <b-col sm="12">
          <b-row>
            <b-col lg="4" class="profile-right">
              <user-info :user= "user" :lead="lead" @click="showPdf"/>
              <invitations></invitations>
            </b-col>
            <b-col lg="8" class="profile-center">
              <tab-content id="pills-tabContent-2">
                <tab-content-item :active="true" id="profile-package" aria-labelled-by="pills-feed-tab">
                  <div class="iq-card p-1">
                    <b-tabs content-class="mt-1 pb-0" align="left">
                      <b-tab title="All" active @click="setgetMemberPackagesOrMemberServices('')">
                      </b-tab>
                      <b-tab title="Active"  @click="setgetMemberPackagesOrMemberServices('active')">
                      </b-tab>
                      <b-tab title="Expired" @click="setgetMemberPackagesOrMemberServices('expired')">
                      </b-tab>
                      <b-tab title="Refunded" @click="setgetMemberPackagesOrMemberServices('refunded')">
                      </b-tab>
                      <b-tab title="Upgraded" @click="setgetMemberPackagesOrMemberServices('upgraded')">
                      </b-tab>
                    </b-tabs>
                  </div>
                  <!--details-->
                  <packageOrServiceDetails :details="getMemberPackagesOrMemberServices" :loading="packageOrServiceDetailsLoading" @reload="setgetMemberPackagesOrMemberServices"/>
                </tab-content-item>
                <tab-content-item :active="false" id="profile-activity" aria-labelled-by="pills-activity-tab">
                  <div class="iq-card p-1">
                    <tab-nav :tabs="true" id="myTab-three">
                      <tab-nav-items :active="true" id="home-tab-three" ariaControls="home" href="#refund-activity" role="tab" :ariaSelected="true" title="Check In" @click="changeTitle('Check In')" @submit="getVisitReport"/>
                      <tab-nav-items :active="false" id="profile-tab-three" ariaControls="profile" href="#profile-three" role="tab" :ariaSelected="false" title="Refund" @click="changeTitle('Refund')" @submit="getRefundReport"/>
                      <tab-nav-items :active="false" id="freeze-tab-three" ariaControls="freeze" href="#freeze-activity" role="tab" :ariaSelected="false" title="Freeze" @click="changeTitle('Freeze')" @submit="getFreezeReport"/>
                      <tab-nav-items :active="false" id="profile-tab-four" ariaControls="freeze"
                                     href="#upgrade-activity"
                                     role="tab" :ariaSelected="false" title="Upgrade" @click="getUpgradedData()"/>
                    </tab-nav>
                  </div>
                  <iq-card>
                    <template v-slot:headerTitle>
                      <h4 class="card-title"> {{ activityTitle }} Activity</h4>
                    </template>
                    <template  v-slot:body>
                      <tab-content id="myTabContent-4">
                        <tab-content-item :active="true" id="refund-activity" aria-labelled-by="home-tab-three">
                          <div v-if="AllVisits.length > 0">
                            <ul class="iq-timeline" id="visitesList">
                              <li v-for="(item,index) in AllVisits" :key="index">
                                <div class="timeline-dots" :class="'border-primary'"></div>
                                <h6 class="float-left mb-1">Check In</h6>
                                <small class="float-right mt-1 font-weight-bold text-success" v-html="item.check_in"></small>
                                <div class="d-inline-block w-100">
                                  <h6 class="float-left mb-1 text-secondary">Check Out</h6>
                                  <small class="float-right mt-1 font-weight-bold text-danger" v-html="item.check_out ? item.check_out : 'In Gym'"></small>
                                </div>
                                <h5 class="float-left mb-1 text-success">Details :</h5>
                                <div class="d-inline-block w-100 pl-4">
                                  <div class="mt-1 font-weight-bold text-secondary">
  <!--                                  {{item.activity[0].member_package.package_title}}-->
                                    <p v-for="(i, key) in item.activity" :key="key">
                                      <span class="text-primary">{{i.member_package.package_title}}</span> =>
                                      <span v-for="(data, key) in i.services" :key="key">
                                        <span class="text-success">{{data.service_title}}</span>
                                      </span>
                                    </p>
                                  </div>
                                </div>
                              </li>
                            </ul>
                            <b-pagination v-model="visiteCurrentPage"
                                          class="mt-3"
                                          :total-rows="visitetotal"
                                          align="right"
                                          :per-page="visiteper_page"
                                          aria-controls="visiteList"
                                          @input="changeContentActivities"
                            ></b-pagination>
                          </div>
                          <div v-else>
                            <iq-card body-class="p-0" class="rounded text-center">
                              <img :src="require('../../assets/images/error/search.png')" class="avatar-200" />
                              <p class="font-size-18 pb-2">Oops!... no data found</p>
                            </iq-card>
                          </div>                  </tab-content-item>
                        <tab-content-item :active="false" id="profile-three" aria-labelled-by="profile-tab-three">
                          <div v-if="allRefunds.length > 0">
                            <ul class="iq-timeline" id="refundsList">
                              <li v-for="(item,index) in allRefunds" :key="index">
                                <div class="timeline-dots" :class="'border-primary'"></div>
                                <h6 class="float-left mb-1">{{ item.member_package.package_title }} <span class="text-black"> - Paid Amount</span> <span class="text-success"> {{ parseFloat(item.cost) }} EGP</span></h6>
                                <small class="float-right mt-1 font-weight-bold text-primary">{{ item.updated_at }}</small>
                                <div class="d-inline-block w-100">
                                  <h6 class="float-left mb-1 text-secondary">
                                    <span v-if="item.status == 'rejected'" class="text-danger">{{ item.status }}</span>
                                    <span v-if="item.status == 'pinding'" class="text-gray">{{ item.status }}</span>
                                    <span v-if="item.status == 'approved'" class="text-success">{{ item.status }} - Refund Amount {{parseFloat(item.value)}} EGP</span>
                                  </h6>
                                </div>
                              </li>
                            </ul>
                            <b-pagination v-model="visiteCurrentPage"
                                          class="mt-3"
                                          :total-rows="visitetotal"
                                          align="right"
                                          :per-page="visiteper_page"
                                          aria-controls="visiteList"
                                          @input="changeContentActivities"
                            ></b-pagination>
                          </div>
                          <div v-else>
                            <iq-card body-class="p-0" class="rounded text-center">
                              <img :src="require('../../assets/images/error/search.png')" class="avatar-200" />
                              <p class="font-size-18 pb-2">Oops!... no data found</p>
                            </iq-card>
                          </div>
                        </tab-content-item>
                        <tab-content-item :active="false" id="freeze-activity" aria-labelled-by="freeze-tab-three">
                          <div v-if="allFreeze.length > 0">
                            <ul class="iq-timeline" id="freezeList">
                              {{item}}
                              <li v-for="(item,index) in allFreeze" :key="index">
                                <div class="timeline-dots" :class="'border-primary'"></div>
                               <h6 class="float-left mb-1"> <span class="text-success">{{ item.member_package.freezing_weeks }} Weeks</span> In <span class="text-success">{{ item.member_package.package_title }} </span></h6>
                                <small class="float-right mt-1 font-weight-bold text-success"> Start -> {{item.start}}</small>
                                <div class="d-inline-block w-100">
                                  <small class="float-right mt-1 font-weight-bold text-danger"> end -> {{item.end}}</small>

                                  <!--                                <h6 class="float-left mb-1 text-secondary">
                                                                    <span class="text-danger">{{ item.member_package }}</span>
                                                                    <span class="text-gray">{{ item.end }}</span>
                                                                    &lt;!&ndash; <span v-if="item.status == 'aproved'" class="text-success">{{ item.status }} - Refund Amount {{parseFloat(item.value)}} EGP</span>&ndash;&gt;
                                                                  </h6>-->
                                </div>
                              </li>
                            </ul>
                            <b-pagination v-model="visiteCurrentPage"
                                          class="mt-3"
                                          :total-rows="visitetotal"
                                          align="right"
                                          :per-page="visiteper_page"
                                          aria-controls="visiteList"
                                          @input="changeContentActivities"
                            ></b-pagination>
                          </div>
                          <div v-else>
                            <iq-card body-class="p-0" class="rounded text-center">
                              <img :src="require('../../assets/images/error/search.png')" class="avatar-200" />
                              <p class="font-size-18 pb-2">Oops!... no data found</p>
                            </iq-card>
                          </div>
                        </tab-content-item>
                        <tab-content-item :active="false" id="upgrade-activity" aria-labelled-by="profile-tab-four">
                          <div v-if="allUpgrades.length > 0">
                            <b-table  striped responsive :items="allUpgrades" class="mb-0 table-borderless">
                            </b-table>
                            <b-pagination v-model="upgrades.currentPage"
                                          class="mt-3"
                                          :total-rows="upgrades.total"
                                          align="right"
                                          :per-page="upgrades.per_page"
                                          @input="getUpgradedData"
                            ></b-pagination>
                          </div>
                          <div v-else>
                            <iq-card body-class="p-0" class="rounded text-center">
                              <img :src="require('../../assets/images/error/search.png')" class="avatar-200" />
                              <p class="font-size-18 pb-2">Oops!... no data found</p>
                            </iq-card>
                          </div>
                        </tab-content-item>
                      </tab-content>
                    </template>
                  </iq-card>
                </tab-content-item>
                <tab-content-item :active="false" id="profile-friends" aria-labelled-by="pills-friend-tab">
                  <iq-card>
                    <template v-slot:headerTitle>
                      <h4 class="card-title">Financial</h4>
                    </template>
                    <template v-slot:body>
                      <div v-if="loadTableFinance" class="text-center">
                        <b-spinner small type="grow"></b-spinner>
                        Loading...
                      </div>
                      <div v-else>
                      <div class="col-md-12 my-1">
                        <b-form-checkbox v-model="actualRemaining" @change="getPaymentsMemberReport">Actual Remaining</b-form-checkbox>
                      </div>
                      <b-table striped responsive :fields="columnsFinance" :items="memberReportFinance"
                               class="mb-0 table-borderless">
                        <template v-slot:cell(member_package.package_title)="data">
                          <p>{{ data.item.member_upgrade.length > 0 ? data.item.member_upgrade[0].package_to.title :
                              data.item.member_package.package_title }}</p>
                        </template>
                        <template v-slot:cell(member.name)="data">
                          <router-link class="linkedReport" :to="{name: 'profile', params:{ id : data.item.member.id }}"><b-badge variant="primary">{{ data.item.member.name }}</b-badge></router-link>
                        </template>
                        <template v-slot:cell(member_package.net)="data">
                          <b-badge pill :variant="'success'">{{ data.item.member_package? data.item.member_package.net : '' }}</b-badge>
                        </template>
                        <template v-slot:cell(pay)="data">
                          <b-badge pill :variant="'primary'">{{ data.item.pay }}</b-badge>
                        </template>
                        <template v-slot:cell(member_upgrade)="data">
                          <p>{{ data.item.member_upgrade.length > 0 ? data.item.member_upgrade[0].package_from.title :
                              '---' }}</p>
                        </template>
                        <template v-slot:cell(remaining)="data">
                          <b-badge pill :variant="'danger'">{{ data.item.remaining }}</b-badge>
                        </template>
                        <template v-slot:cell(member_package.discount)="data">
                          <b-badge pill :variant="'warning'">{{  data.item.member_package? data.item.member_package.discount : '' }}</b-badge>
                        </template>
                        <template v-slot:cell(method)="data">
                          <b-badge pill :variant="'warning'">{{  data.item.method }}</b-badge>
                        </template>
                        <template v-slot:cell(created_at)="data">
                          {{ timeConvert(data.item.created_at) }}
                        </template>
                        <template v-slot:cell(actions)="data1">
                          <b-button variant=" iq-bg-danger" size="sm" @click="showPopupRocPayment(data1.item)">ROC
                          </b-button>
                        </template>
                      </b-table>
                      <b-pagination v-model="currentPageFinance"
                                    class="mt-3"
                                    :total-rows="totalFinance"
                                    align="right"
                                    :per-page="per_pageFinance"
                                    aria-controls="my-table"
                                    @input="getPaymentsMemberReport()"
                      ></b-pagination>
                      </div>
                    </template>
                  </iq-card>
                </tab-content-item>
                <tab-content-item :active="false" id="profile-attachments" aria-labelled-by="pills-attachments-tab">
                  <iq-card body-class="p-0">
                    <template v-slot:headerTitle>
                      <h4 class="card-title">Gallery</h4>
                    </template>
                    <template v-slot:headerAction>
                      <input type="file" id="attachments" @change="previewAttachemnt" class=" position-absolute capture_image" accept="image/*" style="opacity: 0;"/>
                      <p ref="attachments" class="m-0 text-primary cursor-pointer"><i class="las la-image"></i> Add Image</p>
                    </template>
                    <template v-slot:body>
                      <ul class="profile-img-gallary d-flex flex-wrap p-0 m-0" v-if="!loadingGallary">
                        <li v-for="(item,index) in galary" :key="index" class="col-md-3 pl-1 pr-0 pb-1">
                          <b-link :href="item.href" @click="showPopUpImage(item.media.path_info)">
                              <b-img :src="item.media.path_info" alt="attachment" fluid />
                          </b-link>
                        </li>
                      </ul>
                      <p v-else>
                        <spinner-loading text="loading" />
                      </p>
                    </template>
                  </iq-card>
                  <iq-card body-class="p-0">
                    <template v-slot:headerTitle>
                      <h4 class="card-title">Contracts</h4>
                    </template>
                    <template v-slot:body>
                      <b-table striped responsive :fields="columnsContracts" :items="userContract" class="mb-0 table-borderless">
                        <template v-slot:cell(member)="data1">
                          <router-link :to="{name:'profile', params:{id:data1.item.member.id}}">{{data1.item.member.name}}</router-link>
                        </template>
                        <template v-slot:cell(project)="data">
                          <span :style={color:data.item.project.color}>{{data.item.project.en_title}}</span>
                        </template>
                        <template v-slot:cell(created_at)="data">
                          <span>{{timeConvert(data.item.created_at)}}</span>
                        </template>
                        <template v-slot:cell(ContractPackages)="data">
                          <ul class="m-0 p-0">
                            <li v-for="(i, key) in data.item.ContractPackages" :key="key">
                              <span v-if="i.available" class="text-success"><i class="las la-check-circle"></i></span>
                              <span v-else class="text-danger"><i class="las la-times-circle"></i></span>
                              {{i.package_title}}
                            </li>
                          </ul>
                        </template>
                        <template v-slot:cell(view_contract)="data">
                          <b-button variant=" iq-bg-success mr-1 mb-1" size="sm" @click="showContract(data.item.contract_image)">
                            <i class="las la-eye"></i>
                          </b-button>
                          <b-button variant="iq-bg-primary mr-1 mb-1" size="sm" @click="$router.push({name:'contract', params: { memberData: {...user, contractId : data.item.id} } })">
                            <i class="las la-edit"></i>
                          </b-button>
                        </template>
                      </b-table>
                    </template>
                  </iq-card>
                </tab-content-item>
              </tab-content>
            </b-col>
          </b-row>
        </b-col>
      </b-row>
  </template>
<script>
import jsPDF from 'jspdf'
import { core } from '../../config/pluginInit'
import info from '../../components/members/profile/info'
import packageOrServiceDetails from '../../components/members/profile/packageOrServiceDetails'
import { mapGetters } from 'vuex'
import eventBus from '../../eventBus'
import { commonDataMixin } from '@/mixins/commonMixin'
import memberShipService from '@/services/MemberShip/memberShip'
import services from '@/modules/reports/services/reports'
import rocService from '@/components/members/service'
const invitations = () => import('../../components/members/profile/invitations')
const logo = require('../../assets/images/user/img.png')
export default {
  name: 'profile',
  data () {
    return {
      progressUpload: 0,
      loadingGallary: false,
      progressBar: 0,
      userContract: null,
      currentPageUpgrades: 1,
      totalUpgrades: 1,
      per_pageUpgrades: 1,
      loadTableUpgrades: false,
      newPaymentMethod: 'cash',
      loadingDone: false,
      allUpgrades: [],
      upgrades: {
        currentPage: '',
        per_page: '',
        total: ''
      },
      loadTableFinance: false,
      memberReport: [],
      memberReportFinance: [],
      loadTable: true,
      currentPageFinance: 1,
      per_pageFinance: 10,
      totalFinance: '',
      hhh: '',
      user: {
        profile: require('../../assets/images/user/user-11.png'),
        name: '',
        phone: '',
        membership_id: '',
        email: '',
        type: '',
        birthday: '',
        national_id: '',
        QrCode: '',
        id: '',
        lead: {}
      },
      logo: require('../../assets/images/user/max-gym-logo.png'),
      branchName: JSON.parse(localStorage.getItem('default_project')).en_title,
      branchId: JSON.parse(localStorage.getItem('default_project')).id,
      userImage: require('../../assets/images/user/user-11.png'),
      lead: {},
      activityTitle: '',
      visiteCurrentPage: 1,
      visiteper_page: 10,
      visitetotal: 6,
      visiteLoading: true,
      galary: [],
      AllVisits: [],
      allRefunds: [],
      allFreeze: [],
      packageOrServiceDetailsLoading: true,
      financialList: [],
      financialCurrentPage: 1,
      financialper_page: 10,
      financialtotal: 6,
      financialLoading: true,
      popupImageSrc: '',
      actualRemaining: '',
      packageNameRoc: '',
      packageIdRoc: '',
      columnsUpgrades: [
        { label: 'Packages', key: 'member_package.package_title' },
        { label: 'Upgrade From', key: 'member_upgrade' },
        { label: 'Pay', key: 'pay' },
        { label: 'Method', key: 'method' },
        { label: 'Remaining', key: 'remaining' },
        { label: 'Discount', key: 'member_package.discount' },
        { label: 'User', key: 'user.name' }
      ],
      columnsContracts: [
        { label: 'Member', key: 'member' },
        { label: 'Sales', key: 'sales.name' },
        { label: 'Project', key: 'project' },
        { label: 'Package / Service', key: 'ContractPackages' },
        { label: 'Created At', key: 'created_at' },
        { label: 'View Contract', key: 'view_contract' }
      ],
      memberReportUpgrades: [],
      imageContract: ''
    }
  },
  watch: {
    '$route.params.id' () {
      this.getInfoAboutMember()
      this.setgetMemberPackagesOrMemberServices()
      this.getVisitReport()
      this.getAllPayments()
      this.getRefundReport()
      this.getFreezeReport()
      this.getAllAttachments()
      this.getPaymentsMemberReport()
    }
  },
  mixins: [commonDataMixin],
  computed: {
    ...mapGetters(['getMemberPackagesOrMemberServices']),
    columnsFinance () {
      var col = [
        { label: 'Created At', key: 'created_at' },
        { label: 'Packages', key: 'member_package.package_title' },
        { label: 'Upgrade From', key: 'member_upgrade' },
        { label: 'Pay', key: 'pay' },
        { label: 'Method', key: 'method' },
        { label: 'Remaining', key: 'remaining' },
        { label: 'Discount', key: 'member_package.discount' },
        { label: 'User', key: 'user.name' }
      ]
      if (this.hasPer('membership.roc.create')) { col.push({ label: 'Actions', key: 'actions' }) }
      return col
    }
  },
  components: {
    // eslint-disable-next-line vue/no-unused-components
    packageOrServiceDetails,
    invitations,
    userInfo: info
  },
  methods: {
    showContract (data) {
      this.imageContract = data
      this.$bvModal.show('imageContract')
    },
    getAllUpgrades () {
      this.$store.dispatch('getAllUpgrades', { pageNumber: this.currentPageUpgrades, memberId: this.$route.params.id }).then(res => {
        this.allFreeze = res.data.data
        this.currentPageUpgrades = res.data.meta.current_page
        this.per_pageUpgrades = res.data.meta.per_page
        this.totalUpgrades = res.data.meta.total
        this.loadTableUpgrades = false
      })
    },
    getPaymentsMemberReport () {
      this.loadTableFinance = true
      services.getAllPayments({
        pageNumber: this.currentPageFinance,
        userName: '',
        dateFrom: '',
        dateTo: '',
        memberId: this.$route.params.id,
        projectId: JSON.parse(localStorage.getItem('default_project')).id,
        method: '',
        remaining: this.actualRemaining === false ? '' : this.actualRemaining
      }).then(res => {
        this.memberReportFinance = res.data.data.payments.data
        this.currentPageFinance = res.data.data.payments.current_page
        this.per_pageFinance = res.data.data.payments.per_page
        this.totalFinance = res.data.data.payments.total
        /*   this.totalPay = res.data.data.total_pay
        this.totalRemaining = res.data.data.total_remaining */
        this.loadTableFinance = false
      })
    },
    printInfo () {
      // eslint-disable-next-line new-cap
      const doc = new jsPDF()
      /*      doc.imageLoadFromUrl('https://maxgym.net/images/max-gym-logo.png')
        doc.imagePlace(10, 10) */
      var imgLogo = new Image()
      imgLogo.src = logo
      doc.addImage(imgLogo, 'png', 8, 10)
      doc.text('Branch: ' + JSON.parse(localStorage.getItem('default_project')).en_title, 120, 27)
      doc.text('User Info:', 10, 60)
      doc.lineTo(0, 100)
      doc.text('Name:', 10, 70)
      doc.text(this.user.name, 50, 70)
      doc.text('Phone:', 10, 80)
      doc.text(this.user.phone, 50, 80)
      doc.text('Member Id:', 10, 90)
      doc.text(this.user.membership_id, 50, 90)
      doc.text('Package Info: ', 10, 110)
      this.getMemberPackagesOrMemberServices.forEach((data, key) => {
        doc.text(data.title + ` (${'freez: '} ${data.available_freezing}, ${'Invitations: '} ${data.available_invitation})`, 10, 126 + (key * 10))
      })
      doc.output('dataurlnewwindow')
    },
    viewMemberImage () {
      console.log('aaaaaaaaaaaa')
    },
    showPdf () {
      this.$bvModal.show('userCard')
    },
    showPopUpImage (image) {
      this.popupImageSrc = image
      this.$bvModal.show('popupImage')
    },
    resetUser () {
      this.user = {
        progressBar: 0,
        profile: require('../../assets/images/user/user-11.png'),
        name: '',
        phone: '',
        membership_id: '',
        email: '',
        type: '',
        birthday: '',
        national_id: '',
        QrCode: '',
        lead: {}
      }
    },
    getInfoAboutMember () {
      this.resetUser()
      this.$store.dispatch('getMembership', this.$route.params.id).then(res => {
        this.user.name = res.data.data.member.name
        this.user.lead = res.data.data.member.lead
        this.user.phone = res.data.data.member.phone
        this.user.membership_id = res.data.data.member.membership_id
        this.user.type = res.data.data.member.type
        this.user.email = res.data.data.member.email
        this.user.national_id = res.data.data.member.national_id
        this.user.birthday = res.data.data.member.birthday
        this.user.QrCode = res.data.data.code
        this.userImage = res.data.data.member.profile
        this.user.id = res.data.data.member.id
        this.lead = res.data.data.member.lead
      })
    },
    setgetMemberPackagesOrMemberServices (status = '') {
      this.packageOrServiceDetailsLoading = true
      this.$store.dispatch('getMemberPackagesOrMemberServices', { id: this.$route.params.id, type: '', status: status })
        .then(() => {
          this.packageOrServiceDetailsLoading = false
        })
      this.getPaymentsMemberReport()
    },
    getVisitReport () {
      this.activityTitle = 'Check In'
      this.inviteLoading = true
      this.$store.dispatch('getAllVisits', {
        pageNumber: this.visiteCurrentPage,
        memberId: this.$route.params.id
      }).then(res => {
        this.AllVisits = res.data.data
        this.visiteCurrentPage = res.data.meta.current_page
        this.visiteper_page = res.data.meta.per_page
        this.visitetotal = res.data.meta.total
        this.visiteLoading = false
      })
    },
    getAllPayments () {
      this.financialLoading = true
      this.$store.dispatch('getAllPayments', {
        pageNumber: this.financialCurrentPage,
        memberId: this.$route.params.id
      }).then(res => {
        this.financialList = res.data.data.payments.data
        this.financialCurrentPage = res.data.data.payments.current_page
        this.financialper_page = res.data.data.payments.per_page
        this.financialtotal = res.data.data.payments.total
        this.financialLoading = false
      })
    },
    getRefundReport () {
      this.$store.dispatch('getAllRefund', { pageNumber: this.currentPage, memberId: this.$route.params.id }).then(res => {
        this.allRefunds = res.data.data
        this.currentPage = res.data.meta.current_page
        this.per_page = res.data.meta.per_page
        this.total = res.data.meta.total
        this.loadTable = false
      })
    },
    getFreezeReport () {
      this.$store.dispatch('getAllFreeze', { pageNumber: this.currentPage, memberId: this.$route.params.id }).then(res => {
        this.allFreeze = res.data.data
        this.currentPage = res.data.meta.current_page
        this.per_page = res.data.meta.per_page
        this.total = res.data.meta.total
        this.loadTable = false
      })
    },
    changeContentActivities () {
      this.getVisitReport()
    },
    changeContentFinancial () {
      this.getAllPayments()
    },
    changeTitle (title) {
      this.activityTitle = title
    },
    previewImage: function (e) {
      var files = e.target.files || e.dataTransfer.files
      if (!files.length) return
      this.createImage(files[0])
      const fd = new FormData()
      fd.append('file', files[0], files[0].name)
      fd.append('folder', 'member_profile_photo')
      fd.append('access', 'public')
      var config = {
        onUploadProgress: (progressEvent) => {
          const { loaded, total } = progressEvent
          const percent = Math.floor((loaded * 100) / total)
          console.log(percent)
          this.progressBar = percent
        }
      }
      this.commonUploadFiles(fd, config).then(res => {
        /*
          console.log(res.data.data.media_id)
  */
        memberShipService.uploadMemberImage(this.$route.params.id, { media_id: res.data.data.media_id }).then(response => {
          core.showSnackbar('success', response.data.message)
        })
      })
      /*      this.$store.dispatch('uploadMemberImage', { userId: this.user.id, image: fd }).then(res => {
          core.showSnackbar('success', res.data.message)
        }) */
    },
    createImage (file) {
      var reader = new FileReader()
      reader.onload = (e) => {
        this.userImage = e.target.result
      }
      reader.readAsDataURL(file)
    },
    previewAttachemnt: function (e) {
      var config = {
        onUploadProgress: (progressEvent) => {
          const { loaded, total } = progressEvent
          const percent = Math.floor((loaded * 100) / total)
          console.log(percent)
          this.progressUpload = percent
        }
      }
      var files = e.target.files || e.dataTransfer.files
      if (!files.length) return
      this.createAttachemnt(files[0])
      const fd = new FormData()
      fd.append('file', files[0], files[0].name)
      fd.append('folder', 'member_attachments')
      fd.append('access', 'public')
      this.commonUploadFiles(fd, config).then(res => {
        memberShipService.addMemberAttachments(this.$route.params.id, { media_ids: [res.data.data.media_id] }).then(response => {
          this.getAllAttachments()
          core.showSnackbar('success', response.data.message)
        })
      })
    },
    createAttachemnt (file) {
      var reader = new FileReader()
      reader.readAsDataURL(file)
    },
    getAllAttachments () {
      this.loadingGallary = true
      memberShipService.getMemberAttachments(this.$route.params.id).then(res => {
        this.galary = res.data.data
      }).finally(() => {
        this.loadingGallary = false
      })
    },
    getUpgradedData () {
      this.activityTitle = 'Upgrades'
      memberShipService.getUpgradetPackagesDetails(this.$route.params.id).then(res => {
        this.allUpgrades = res.data.data
        this.upgrades.currentPage = res.data.meta.current_page
        this.upgrades.per_page = res.data.meta.per_page
        this.upgrades.total = res.data.meta.total
      })
    },
    showPopupRocPayment (data) {
      this.packageNameRoc = data.member_package.package_title
      this.packageIdRoc = data.member_package.id
      this.newPaymentMethod = data.method
      this.rowIdRoc = data.id
      rocService.getAllReasonDependOnType('payment_method').then(res => {
        this.allReason = res.data.data
        this.$bvModal.show('rocPayment')
      })
    },
    isSelected (i) {
      // eslint-disable-next-line eqeqeq
      return i == this.newPaymentMethod
    },
    rocPayment () {
      this.loadingDone = true
      rocService.rocRequest({
        package: this.packageIdRoc,
        reason: this.rocReasonId,
        value: this.newPaymentMethod,
        row: this.rowIdRoc
      }).then(res => {
        core.showSnackbar('success', res.data.message)
        this.$bvModal.hide('rocPayment')
      }).finally(() => {
        this.loadingDone = false
      })
    },
    getAllUserContract () {
      memberShipService.getAllUserContract(1, this.branchId, this.$route.params.id).then(res => {
        this.userContract = res.data.data.leads.data
      })
    }
  },
  mounted () {
    core.index()
    eventBus.$on('checkIn_event', () => {
      this.getVisitReport()
      this.setgetMemberPackagesOrMemberServices()
    })
  },
  beforeDestroy () {
    eventBus.$off('checkIn_event')
  },
  created () {
    this.getInfoAboutMember()
    this.setgetMemberPackagesOrMemberServices()
    this.getVisitReport()
    this.getAllPayments()
    this.getRefundReport()
    this.getFreezeReport()
    this.getAllAttachments()
    this.getPaymentsMemberReport()
    this.getAllUpgrades()
    this.getAllUserContract()
  }
}
</script>
  <style lang="css">
  .profile-img{
    margin-top: 0 !important;
  }
  .dropdown-menu li {
    padding: 4px 0px !important;
    border-bottom: 1px solid #f3f1f1 !important;
    transition: all 0.4s ease-in-out;
  }
  .dropdown-menu li:hover {
    padding-left: 6px !important;
  }
  .modal-body .w-250px ,.modal-body  .sign_image{
    width: 250px !important;
  }
  .loadingSection {
    position: absolute;
    top: 0;
    display: flex;
    left: 0;
    width: 80px;
    height: 80px;
    justify-content: center;
    align-items: center;
    font-size: 24px;
    background: #000000;
    border-radius: 50%;
  }
  .loading-full-screen {
    z-index: 1000000000;
    background: #000000b3;
    top: 0;
    left: 0;
    font-size: 50px;
    font-weight: bold;
  }
  </style>
